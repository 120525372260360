.about_section {
  margin-top: 5px;
  margin-bottom: 3rem;
}


.about_section .content .title {
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}

.about_section .content .title.mobile:before {
  content: "";
  position: absolute;
  width: 35%;
  height: 3px;
  bottom: 0;
  left: 32%;
  border-bottom: 1px solid black;
  /* font-size: 20px !important; */
}

.about_section .content .title.desktop:before {
  content: "";
  position: absolute;
  width: 10% !important;
  height: 3px;
  bottom: 0;
  left: 45%;
  border-bottom: 1px solid black;
}

.material_extended {
  margin-top: 1.8rem;
}

.material_extended .extended_child:not(:last-child) {
  margin-bottom: 0.8rem;
}

/* .about_section .about_text h1 {
  font-size: 50px;
  line-height: 100%;
  margin-bottom: 15px;
  color: var(--color-primary);
}

.about_section .about_text p {
  font-size: 20px;
  line-height: 33px;
  color: var(--light-gray);
} */


@media (max-width: 899px) {

  .about_section .about_text h2 {
    font-size: 30px !important;
  }

  .about_section .about_text p {
    line-height: 30px;
  }

  .about_section .content .title {
    font-size: 20px !important;
  }

  .material_extended .extended_child {
    font-size: 14px !important;
  }

  .material_extended .extended_child h3 {
    font-size: 16.5px !important;
  }
}