.product_banner {
  height: calc(45vh - 70px) !important;
  display: flex;
}

.product_banner .product_text h1 {
  font-size: 40px;
  line-height: 100%;
  margin-bottom: 15px;
  text-align: center;
  color: var(--color-primary);
}

.product_banner .product_text p {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: var(--light-gray);
}

.imported_product {
  display: flex;
  padding: 25px 0;
}

.imported_product .product_text h1 {
  font-size: 40px;
  line-height: 100%;
  margin-bottom: 15px;
  color: var(--color-primary);
}

.imported_product .product_text p {
  font-size: 18px;
  line-height: 30px;
  color: var(--light-gray);
}

.product_card {
  background: white;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  box-shadow: #dbe2ee 0 0 20px 5px;
  color: #191847;
  margin-bottom: 25px;
}

.cover_image {
  width: 100%;
  overflow: hidden;
}

.product_image img:hover {
  transform: scale(1.05);
}


.image_container img {
  width: 100%;
  object-fit: cover;
}

.free_shipping {
  display: inline-block;
  background: #191847;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 14px;
  color: white;
  font-weight: 400;
}

.product_name {
  /* margin: 10px 0 20px 0; */
  margin: 10px 0 0 0;
  font-size: 16px;
}

.stock {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stock_status {
  width: 14px;
  height: 14px;
  background: #00d98b;
  border-radius: 50%;
}

.stock_info {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.product_list_body {
  margin-bottom: 65px;
}