.business_location {
  margin-top: 5px;
  margin-bottom: 3rem;
}

.business_location .business_text h1 {
  font-size: 50px;
  line-height: 100%;
  margin-bottom: 15px;
  color: var(--color-primary);
}

.business_location .business_text p {
  font-size: 20px;
  /* line-height: 33px; */
  color: var(--light-gray);
}

.text_content .title {
  margin: 41px 0px 15px 0px;
}

@media (max-width: 899px) {

  .business_locations .business_text h2 {
    font-size: 30px !important;
  }

  .business_location .business_text p {
    font-size: 16px !important;
  }

  .business_locations .business_text p {
    line-height: 30px;
  }

}