.footer_body {
    background-color: #2B2935;
    color: white;
    padding: 80px 0;
}

.footer_about p {
    margin-top: 15px;
    font-weight: 500;
    font-size: 15px !important;
    line-height: 1.5;
    color: #dedede;
}

.footer_item.link li a {
    font-weight: 500;
    /* color: #dedede; */
    color: white;
    border-bottom: 1px solid;
}

.footer_item.link li a:hover {
    /* text-decoration: underline; */
    color: var(--color-primary);
}

.footer_item.contact li a {
    font-weight: 500;
    color: white;
    /* color: #dedede; */
}

.footer_item li {
    padding-bottom: 17px;
    font-weight: 500;
    color: #dedede;
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;
    line-height: 1.4;
}

.footer_item_title {
    margin-bottom: 30px;
}

.footer_info_body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.footer_bottom {
    background-color: #1b1a1e;
    color: white;
    padding: 25px 0 !important;
}

.content {
    line-height: 1.5;
}

.terms ul {
    display: flex;
    gap: 35px;
    justify-content: flex-end;
}

.footer_body .container {
    padding: 0 25px;
}

.footer_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
}

.footer_grid_columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

@media (max-width: 899px) {
    .footer_body {
        padding: 55px 0;
    }

    .footer_grid {
        grid-template-columns: 1fr;
        gap: 9px;
    }

    .footer_item {
        margin-top: 25px;
    }

    .footer_item_title {
        margin-bottom: 15px;
    }

    .footer_info_body {
        display: block;
    }

    .copy_right {
        font-size: 12px;
        text-align: center;
    }

    .copy_right a {
        color: white !important;
    }

    .terms ul {
        justify-content: center;
    }

    .footer_grid_columns {
        justify-content: center;
        gap: 31px;
    }

    .contact {
        margin-top: 41px;
        word-break: break-word;
    }
}

@media (min-width: 1200px) {
    .footer_body .container {
        margin: auto;
        padding: 0 15px;
    }
}