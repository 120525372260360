.banner_section {
  height: calc(100vh - 70px) !important;
  background-color: var(--color-primary);
  color: #fff;
  display: flex;
}

.banner_text h1 {
  font-size: 65px;
  line-height: 100%;
  margin-bottom: 30px;
}

.banner_text p {
  font-size: 27px;
  line-height: 40px;
}

.our_products {
  margin-top: 25px;
  /* background-color: #ccc4ff; */
  /* padding: 55px 0; */
}

.business_locations {
  padding-top: 55px;
  height: calc(80vh - 70px) !important;
  display: flex;
}

.gradient_bg {
  background-image: linear-gradient(white, #eaffff);
}

.hedar_text {
  text-align: center;
  margin-bottom: 40px;
}

.our_product_info ul li img {
  border-radius: 5px;
}

.others_hedar_text h2 {
  font-size: 50px !important;
  margin-bottom: 30px;
}

.others_hedar_text p {
  font-size: 27px;
  line-height: 40px;
}

.hedar_text h2 {
  font-size: 65px !important;
}

.sub_hedar_text {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: var(--color-primary);
}

.our_product_info {
  margin-top: 50px;
}

.location_pic {
  margin: -80px;
}

.read_more_body {
  text-align: center;
}

.product_card {
  position: relative;
  overflow: hidden;
}

.overlayer {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.view_all {
  position: absolute;
  top: 40%;
  text-align: center;
  left: 32%;
}

.overlayer .view_all button {
  background: var(--color-primary);
  color: aliceblue;
}

.overlayer .view_all h4 {
  color: var(--color-primary);
  margin-top: 15px;
  font-weight: 700;
}

.product_name {
  font-family: 'remixicon';
  text-transform: capitalize;
  text-align: center;
  font-size: 1.7rem !important;
  letter-spacing: 0.5px;
  word-break: break-word;
}

/* .view_all .product_card {
  height: 250px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  cursor: pointer;
  color: var(--color-primary);
  font-weight: 600;
} */

@media (max-width: 899px) {
  .banner_text h1 {
    font-size: 25px !important;
  }

  .view_all .product_card {
    height: 70px;
    border-radius: 7%;
    margin: auto;
  }

  .banner_section {
    height: inherit !important;
  }

  .banner_section .MuiGrid-container {
    padding: 0px !important;
  }

  .mobile_reverse .MuiGrid-container {
    /* flex-direction: column-reverse; */
    gap: 35px;
  }

  .sub_hedar_text {
    font-size: 1rem !important;
  }

  .hedar_text h2 {
    font-size: 30px !important;
    margin-bottom: 55px;
  }

  .banner_text p {
    line-height: 30px;
  }

  .our_product_info {
    margin-top: 35px;
  }

  .our_product_info .MuiImageList-root {
    display: block;
  }

  .our_product_info .MuiImageListItem-root {
    margin-bottom: 20px;
  }

  .location_pic {
    margin: 0px;
  }

  .business_locations {
    padding: 35px 0;
    height: inherit !important;
  }

  .business_locations .pl_75 {
    padding: 0;
    margin-top: 10px;
  }

  .others_hedar_text p {
    line-height: 28px;
  }

  .others_hedar_text h2 {
    font-size: 24px !important;
  }

  .read_more {
    margin-top: 15px !important;
  }

  .gradient_bg .business_locations {
    border-top: 1px solid #ccc;
  }
}

@media (min-width: 1025px) {
  .banner_section .MuiGrid-container {
    margin: 0;
  }

  .banner_section .css-1qet4ns-MuiGrid-root>.MuiGrid-item {
    padding-left: 0px !important;
  }

  .banner_section img {
    width: 127.5%;
  }
}