@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap');

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  word-break: break-all;
  letter-spacing: 0.25px;
  line-height: 1.3;
}


/* Varialbes */
:root {
  --color-primary: #4c35de;
  --light-gray: #6a6a6a;
  /* --black: #000000;
  --dark: #1f4e3d;
  --white: #ffffff;
  --color-secondary: #f07e01;
  --color-heading: #04000b;
  --color-paragraph: #484747; */
}



/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}


* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  width: 100%;
  word-break: break-all;
}

/* ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
} */
.d-block {
  display: block !important;
}

.margin-top-content {
  margin-top: 0.6rem;
  ;
}

.margin-bottom-content {
  margin-bottom: 0.6rem;
  ;
}

.margin-left-content {
  margin-left: 3rem;
}

img {
  width: 100%;
}

main {
  margin-top: -10px;
  padding: 0px !important;
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

.pl_75 {
  padding-left: 75px;
}

.read_more {
  text-transform: capitalize !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  margin-top: 50px !important;
}

.gap-15 {
  gap: 15px;
}

.align-items-center {
  align-items: center;
}

.d-flex {
  display: flex;
}

.break-word {
  word-break: break-word;
}

.text_content {
  word-break: break-word;
}

.text_content h2 {
  word-break: break-word;
}

.text_content p {
  word-break: break-word;
}

@media (max-width: 899px) {

  h1,
  h2,
  h3 {
    font-size: 18px !important;
  }

  p {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .MuiGrid-container {
    padding: 0 20px;
    width: 100% !important;
  }

  .MuiGrid-item {
    padding: 0px;
    padding-left: 0px !important;
    padding-top: 0px !important;
  }

  .margin-left-content {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
  }
}