.privacy_policy_section {
    margin-bottom: 20px;
}

.privacy_policy_section .title {}



.privacy_policy_section .head_content {
    margin-top: 20px;
}

.privacy_policy_section .head_content h1 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
    text-align: center;
}

.privacy_policy_section .head_content h1::before {
    content: "";
    position: absolute;
    width: 35%;
    height: 3px;
    bottom: 0;
    left: 32%;
    border-bottom: 1px solid black;
}

.privacy_policy_section .head_content .other_information p:nth-child(2) {
    margin-top: 15px;
}

.privacy_policy_section .head_content .information li {
    margin-top: 5px;
}