.logo img {
  width: 100px;
}

.logo {
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.desktop_menu_body {
  background-color: var(--color-primary) !important;
  height: 75px;
}

.dropdowen .MuiAccordionSummary-root {
  background: transparent;
  background-color: var(--color-primary);
  min-height: auto;
}

.dropdowen .MuiTypography-root {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.dropdowen .MuiCollapse-root {
  position: absolute;
  background: white;
  border-radius: 10px;
  width: 215px;
}

.dropdowen .MuiAccordionSummary-expandIconWrapper {
  color: white;
}

.menus {
  display: flex !important;
  align-items: baseline;
}

.menu_body a {
  text-align: right;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  margin: 0 20px;
  color: white;
}

.menu_body .menu_btn {
  font-size: 16px;
  font-weight: 600;
  color: white;
  position: relative;
  text-transform: capitalize;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.mobile_header {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.mobile_logo,
.mobile_header {
  opacity: 0;
  visibility: hidden;
  gap: 10px;
}



/* responsive media */
@media (max-width: 899px) {
  .mobile_menu .dropdowen .MuiAccordionSummary-root {
    background-color: transparent;
    padding: 0;
  }

  .mobile_menu .dropdowen .MuiAccordionSummary-expandIconWrapper {
    color: #313030;
  }

  .mobile_menu .dropdowen {
    width: 100% !important;
  }

  .mobile_menu .dropdowen .MuiTypography-root {
    color: #313030;
    font-size: 15px;
  }

  .mobile_menu .MuiAccordionDetails-root {
    border-left: 1px solid;
  }

  .mobile_menu .dropdowen .MuiCollapse-root {
    position: inherit;
  }

  .mobile_logo {
    font-size: 1.3rem;
  }

  .desktop_menu_body {
    height: auto;
  }

  .desktop_menu_body .MuiToolbar-root {
    height: 55px;
  }

  .mobile_header .mobile_logo,
  .mobile_header,
  .mobile_header .MuiButtonBase-root {
    opacity: 1;
    visibility: visible;
  }

  .mobile_header .MuiButtonBase-root {
    display: block !important;
    margin: 0;
    margin-top: 5px;
  }

  .mobile_header .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .mobile_menu {
    padding: 15px;
    height: 100%;
  }

  .mobile_drawer .MuiDrawer-paper {
    width: 290px !important;
  }

  .mobile_menu .logo {
    color: var(--color-primary);
    font-size: 20px;
  }

  .mobile_logo_body {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .mobile_menu ul {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  .mobile_menu ul a {
    padding: 15px 0;
    font-size: 15px;
    font-weight: 600;
    color: #313030;
  }

  .mobile_menu ul,
  .mobile_menu li {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;
  }
}

@media (min-width: 900px) {

  .mobile_logo,
  .mobile_header {
    display: none;
  }
}

@media (min-width: 1200px) {
  .MuiToolbar-root {
    min-width: 1200px;
    margin: auto;
  }

  /* .MuiPaper-root {
    display: none;
  } */
}